import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    IconButton,
    CircularProgress,
    TextField,
    Typography,
    Paper,
    Container,
    InputAdornment
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { CustomCard, CustomCardContent, Heading } from '../style/CustomCard';

const MessagesCard = ({ accessToken }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const messagesContainerRef = useRef(null);
    const fetchIntervalRef = useRef(null);

    const apiBaseUrl = 'https://api2.enduroco.in';

    const fetchMessages = async () => {
        if (!accessToken) return;

        try {
            const response = await axios.get(`${apiBaseUrl}/messages/user`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setMessages(response.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages();

        const handleVisibilityChange = () => {
            if (document.hidden) {
                clearInterval(fetchIntervalRef.current);
            } else {
                fetchMessages();
                fetchIntervalRef.current = setInterval(fetchMessages, 100000);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        fetchIntervalRef.current = setInterval(fetchMessages, 100000);

        return () => {
            clearInterval(fetchIntervalRef.current);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [accessToken, apiBaseUrl]);

    const handleSendMessage = async () => {
        if (!newMessage || !accessToken) return;

        try {
            await axios.post(`${apiBaseUrl}/messages/send`, {
                Content: newMessage,
                Sender: 'Me'
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            fetchMessages();
            setNewMessage('');
        } catch (error) {
            setError(error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    useEffect(() => {
        const scrollToBottom = () => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }
        };

        if (messages.length) {
            scrollToBottom();
        }
    }, [messages]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    return (
        <CustomCard>
            <CustomCardContent>
                <Heading>Messages</Heading>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Feel free to ask us anything! Please give us 48 hours to reply.
                </Typography>
                <Container maxWidth="sm">
                    <Box
                        ref={messagesContainerRef}
                        sx={{
                            height: '300px',
                            overflowY: 'auto',
                            border: '1px solid #ddd',
                            p: 2,
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            bgcolor: '#f9f9f9',
                        }}
                    >
                        {messages.map(message => (
                            <Box
                                key={message.MessageID}
                                display="flex"
                                flexDirection={message.Sender === 'Me' ? 'row' : 'row-reverse'}
                                alignItems="flex-start"
                                mb={1}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: 1,
                                        bgcolor: message.Sender === 'Me' ? '#e3f2fd' : '#e0e0e0',
                                        color: message.Sender === 'Me' ? 'text.primary' : 'text.secondary',
                                        borderRadius: 2,
                                        maxWidth: '70%',
                                        textAlign: message.Sender === 'Me' ? 'left' : 'right'
                                    }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        {new Date(message.Timestamp).toLocaleString()}
                                    </Typography>
                                    <Typography variant="body1">{message.Content}</Typography>
                                </Paper>
                                <Typography variant="caption" color="textSecondary" sx={{ ml: 1, mr: 1 }}>
                                    {message.Sender === 'Me' ? 'Me' : 'Coach'}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            placeholder="Type your message here..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            sx={{ mb: 1 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSendMessage} color="primary">
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Container>
            </CustomCardContent>
        </CustomCard>
    );
};

export default MessagesCard;
