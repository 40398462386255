import React from "react";
import { Typography, useTheme } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import moment from "moment";
const getIconForEventType = (eventType) => {
  switch (eventType) {
    case "Run":
      return <DirectionsRunIcon fontSize="small" />;
    case "Ride":
      return <DirectionsBikeIcon fontSize="small" />;
    case "Swim":
      return <PoolIcon fontSize="small" />;
    case "Strength":
      return <FitnessCenterIcon fontSize="small" />;
    default:
      return null; // Or a default icon
  }
};
const formatDistance = (distance) =>
  distance ? `${(parseFloat(distance) / 1000).toFixed(0)}km` : "";

const formatTime = (seconds) => {
  if (!seconds) return "";
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return hours > 0 ? `${hours}h${minutes % 60}m` : `${minutes % 60}m`;
};

const formatDuration = (durationInSeconds) => {
  const duration = moment.duration(durationInSeconds, "seconds");
  return (
    Math.floor(duration.asHours()) +
    moment.utc(duration.asMilliseconds()).format(":mm:ss")
  );
};

const CalendarEventTopStrip = ({ event }) => {
  const theme = useTheme(); // Use theme hook

  const containerStyle = {
    backgroundColor:
      event.eventtype === "activity"
        ? "#c8e6c9"
        : theme.palette.grey[300],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontSize: "1.0rem",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "5px 5px 0 0", // Rounded corners only on the top
  };

  return (
    <div style={containerStyle}>
      {getIconForEventType(event.woType)}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {event.eventtype === "activity" ? (
          <>
            {formatTime(event.movingTime) && (
              <Typography
                style={{
                  fontSize: ".9rem",
                  marginLeft: "8px",
                  color: theme.palette.text.primary,
                }}
              >
                {formatTime(event.movingTime)}
              </Typography>
            )}
            {event.distance && (
              <Typography
                style={{
                  fontSize: ".9rem",
                  marginLeft: "8px",
                  color: theme.palette.text.secondary,
                }}
              >
                {formatDistance(event.distance)}
              </Typography>
            )}
          </>
        ) : (
          event.eventtype !== "activity" &&
          Number.isFinite(event.duration) && (
            <Typography
              style={{
                fontSize: ".9rem",
                marginLeft: "8px",
                color: theme.palette.text.primary,
              }}
            >
              {formatDuration(event.duration * 60)}
            </Typography>
          )
        )}
      </div>
    </div>
  );
};

export default CalendarEventTopStrip;
