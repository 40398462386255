import React from "react";
import Grid from "@mui/material/Grid"; // Grid version 2 from MUI
import moment from "moment";
import CalendarDay from "./CalendarDay";

const CalendarBody = ({
  id,
  currentWeek,
  events,
  setOpenWorkoutView,
  setSelectedEvent,
}) => {
  const startOfWeek = currentWeek.clone().startOf("isoWeek");
  const endOfWeek = currentWeek.clone().endOf("isoWeek");
  const days = [];

  let day = startOfWeek;
  while (!day.isAfter(endOfWeek, "day")) {
    days.push(day.clone());
    day.add(1, "day");
  }

  // Format the currentWeek to a string for the data-week attribute
  const weekIdentifier = startOfWeek.format("YYYY-MM-DD");

  return (
    <Grid container sx={{ flexGrow: 1 }} id={id} data-week={weekIdentifier}>
      {days.map((day, index) => (
        <CalendarDay
          key={day.format()}
          day={day}
          events={events.filter((event) =>
            moment(event.date).isSame(day, "day")
          )}
          setOpenWorkoutView={setOpenWorkoutView}
          setSelectedEvent={setSelectedEvent}
        />
      ))}
    </Grid>
  );
};

export default CalendarBody;
