import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const LoadTypography = ({ load }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        background: theme.palette.action.hover, // Subtle background highlight
        borderRadius: "4px",
        padding: "2px 4px",
        marginBottom: "4px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "0.75rem", // Smaller font size
          fontWeight: "400", // Lighter font weight
          color: theme.palette.text.secondary, // Lighter text color for a more subdued appearance
        }}
      >
        {`Load ${Number.isFinite(load) ? load : ""}`}
      </Typography>
    </Box>
  );
};
