import React, { createContext, useContext, useState, useEffect } from "react";
import useSWR from "swr";
import { useFetcher } from "../../../utils/fetcher";
const DataContext = createContext();

export function useCalendarData() {
  return useContext(DataContext);
}

export const CalendarDataProvider = ({ user, children }) => {
  const { fetcher, accessToken } = useFetcher();

  const { data, error } = useSWR(
    accessToken
      ? `https://api2.enduroco.in/getworkouts?`
      : null,
    fetcher
  );

  const addDateAndDurationToWorkouts = (data) => {
    if (!data || !data.workouts) return data;

    const updatedWorkouts = data.workouts.map((day) => ({
      ...day,
      workouts: day.workouts.map((workout) => {
        const durationValue = workout.workout_doc?.duration;
        const formattedDuration =
          durationValue !== undefined
            ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
              (durationValue % 3600) / 60
            )}m`
            : "";
        return {
          ...workout,
          date: day.workoutdate,
          readableDuration: formattedDuration,
          structure: workout.workout_doc,
        };
      }),
    }));

    return { ...data, workouts: updatedWorkouts };
  };

  const updateddata = data ? addDateAndDurationToWorkouts(data) : data;

  const value = { data: updateddata, error };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
