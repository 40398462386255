import * as React from "react";
import { useState, useEffect } from "react";
import CalendarUpcomingLoader from "../../calendarUpcomingLoader";
import { getConfig } from "../../../config";
import WorkoutTextView from "./components/workoutTextView";

import Grid from "@mui/material/Unstable_Grid2";
import { Box, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Schedule as ScheduleIcon } from "@mui/icons-material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { blueGrey, deepOrange } from "@mui/material/colors";
import {
  formatDate,
  transformTitle,
  getBgColor,
  CustomPaper,
} from "../helpers/calendarHelpers";
import BarChart from "./components/workoutChart/components/BarChart";
import { createData } from "../helpers/createData";
import { useCalendarData } from "./calendarDataProvider";

export default function CalendarUpcomingTable({ user, setnavigateFlag }) {
  const { data, error } = useCalendarData();
  const [currentMonday, setcurrentMonday] = useState(moment().day("Monday"));
  const [weekFormat, setWeekFormat] = useState(
    moment().format("MMM D") +
    " - " +
    moment().add(6, "days").format("MMM D, YYYY")
  );
  const [open, setOpen] = useState(false);
  const [woObj, setwoObj] = useState();
  function handleDateButtonClick(type) {
    var weekStartLocal = currentMonday.clone();
    var currentDay = moment();

    if (type === "backward") {
      var targetMonday = weekStartLocal.clone().subtract(7, "days");

      if (currentDay.diff(targetMonday, "days") > 27) {
        // More than 15 days in the past, ignore this operation
        return;
      }

      setcurrentMonday(targetMonday);
      setWeekFormat(
        targetMonday.clone().format("MMM D") +
        " - " +
        targetMonday.clone().add(6, "days").format("MMM D, YYYY")
      );
    } else if (type === "forward") {
      var targetMonday = weekStartLocal.clone().add(7, "days");

      if (targetMonday.diff(currentDay, "days") > 20) {
        // More than 15 days in the future, ignore this operation
        return;
      }

      setcurrentMonday(targetMonday);
      setWeekFormat(
        targetMonday.clone().format("MMM D") +
        " - " +
        targetMonday.clone().add(6, "days").format("MMM D, YYYY")
      );
    } else if (type === "today") {
      setcurrentMonday(moment().day("Monday"));
      setWeekFormat(
        moment().day("Monday").clone().format("MMM D") +
        " - " +
        moment().day("Monday").clone().add(6, "days").format("MMM D, YYYY")
      );
    }
  }
  const handleEventClick = (info) => {
    setOpen(true);
    setwoObj(info);
  };

  if (error) {
    // Check for specific network errors that occur when there's no internet connection
    if (
      error.message.includes("Network request failed") ||
      error.message.includes("Failed to fetch")
    ) {
      return "Please check your internet connection and try again.";
    } else {
      return "An error has occurred.";
    }
  }

  if (!data) return <CalendarUpcomingLoader />;
  if (data.error) {
    return (
      <Box p={2}>
        <Alert severity="error">
          <strong>Setup Incomplete!</strong> Please go to the settings tab and
          connect to Strava.
        </Alert>
      </Box>
    );
  }
  if (data.message) {
    setnavigateFlag(true);
    return <Typography>{data.message}</Typography>;
  }
  const { rows, totalTSS } = createData(data, currentMonday);

  return (
    <Paper elevation={3}>
      <Grid container component={Paper} sx={{ maxWidth: "800px" }}>
        <Grid xs={12}>
          {/* Header Row */}
          <Grid container alignItems="center">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: (() => {
                  const currentDateMonday = moment().day(1).format("MMM DD"); // get current date's Monday
                  return currentDateMonday === currentMonday.format("MMM DD")
                    ? blueGrey[300]
                    : "inherit";
                })(),

                p: 2,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Add this line to add a shadow
                borderRadius: "5px", // Add this line to add rounded corners
              }}
            >
              <Grid
                xs={6}
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  padding: "5px",
                  fontSize: "20px",
                }}
              >
                <Typography variant="h6" align="left" textAlign={"inherit"}>
                  Workout Calendar
                </Typography>
                {weekFormat}
              </Grid>
              <Grid xs={6} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{ margin: "0 5px 0 0", backgroundColor: blueGrey["900"] }}
                  onClick={() => handleDateButtonClick("today")}
                >
                  Today
                </Button>
                <IconButton
                  color="primary"
                  variant="contained"
                  sx={{ margin: "0 5px 0 0" }}
                  onClick={() => handleDateButtonClick("backward")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  variant="contained"
                  sx={{ margin: "0 5px 0 0" }}
                  onClick={() => handleDateButtonClick("forward")}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Second Row P*/}
        <Grid xs={12}>
          <Paper elevation={0} sx={{ p: 2, backgroundColor: blueGrey[50] }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">Total TSS:</Typography>
              <Typography variant="body1">{totalTSS}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid xs={12}>
          {rows.map((row) =>
            row.day ? (
              <CustomPaper
                item
                xs={12}
                style={{
                  boxShadow: "0 0 px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Grid
                  container
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    p: 0,
                    minWidth: 300,
                    // borderTop: "1px solid",
                    // borderBottom: "1px solid #D3D3D3",
                  }}
                >
                  <Grid
                    xs={5}
                    sx={{
                      bgcolor: getBgColor(row.date, "Dayrow"),

                      textAlign: "left",
                      fontWeight: "bold", // Add this line to make the text bold
                      padding: "5px", // Add this line to add padding
                      fontSize: "1.2rem",
                    }}
                  >
                    {row.day}
                  </Grid>
                  <Grid
                    xs={3}
                    sx={{
                      bgcolor: getBgColor(row.date, "Dayrow"),
                      paddingTop: "5px",
                      fontSize: "16px",
                      display: "flex", // Add this line to enable flexbox
                      alignItems: "top", // Add this line to vertically center the text
                    }}
                  >
                    {row.fitness && `Fitness ${row.fitness}`}
                  </Grid>
                  <Grid
                    xs={4}
                    sx={{
                      bgcolor: getBgColor(row.date, "Dayrow"),

                      textAlign: "right",
                      fontWeight: "bold",
                      padding: "5px", // Add this line to add padding
                    }}
                  >
                    {formatDate(row.date)}
                  </Grid>
                </Grid>
              </CustomPaper>
            ) : row.activity ? (
              <Grid
                xs={12}
                sx={{
                  boxShadow:
                    row.type === "workout"
                      ? "0 0 3px rgba(0, 0, 0, 0.1)"
                      : "none",
                  cursor: row.type === "workout" ? "pointer" : "default",
                  "&:hover":
                    row.type === "workout"
                      ? {
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Increase shadow on hover
                        backgroundColor: "#f5f5f5", // Add a slight background color change
                      }
                      : {},
                }}
                onClick={() => handleEventClick(row)}
              >
                <Paper elevation={0}>
                  <Grid
                    container
                    sx={{
                      bgcolor: getBgColor(row.date, row.type),
                      minWidth: 300,
                      boxShadow: "none",
                      paddingY: "8px",
                      fontSize:
                        row.type === "activity" || row.type === "pastworkout"
                          ? "0.9rem"
                          : "1.1rem",
                      borderRadius: "5px",
                    }}
                    key={row.name}
                  >
                    <Grid
                      container
                      xs={9}
                      sm={8}
                      justifyContent={{ xs: "flex-end", sm: "flex-start" }}
                    >
                      <Grid xs={1} sm={1}>
                        <Box
                          component="div"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          {row.activityType === "Run" && (
                            <DirectionsRunIcon
                              style={{
                                color: deepOrange["A700"],
                                fontSize: "30px",
                              }}
                            />
                          )}
                          {row.activityType === "Ride" && (
                            <DirectionsBikeIcon
                              style={{
                                color: deepOrange["A700"],
                                fontSize: "30px",
                              }}
                            />
                          )}
                          {row.activityType === "Swim" && (
                            <PoolIcon
                              style={{
                                color: deepOrange["A700"],
                                fontSize: "30px",
                              }}
                            />
                          )}
                          {row.activityType === "Strength" && (
                            <FitnessCenterIcon
                              style={{
                                color: deepOrange["A700"],
                                fontSize: "30px",
                              }}
                            />
                          )}
                          {/*row.activityType === "swim" && <SwimIcon />}
                          {row.activityType === "strength" && (
                            <StrengthIcon />
                          )}{" "}
                          */}
                        </Box>
                      </Grid>
                      <Grid xs={11} sm={11}>
                        <Box
                          component="div"
                          sx={{
                            color: "#2e2e2e",
                            textTransform: "capitalize",
                            textAlign: "left",
                            paddingLeft: "6px",
                          }}
                        >
                          {transformTitle(row.activity)}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container xs={3} sm={4}>
                      <Grid xs={12} sm={6}>
                        <Box
                          component="div"
                          scope="row"
                          sx={{
                            marginLeft: "0px",
                            textAlign: "left",
                            fontSize: "0.9rem",
                          }}
                        >
                          {row.duration ? (
                            <React.Fragment>
                              <ScheduleIcon sx={{ marginRight: "5px" }} />
                              {row.duration}
                            </React.Fragment>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <Box
                          component="div"
                          scope="row"
                          sx={{
                            marginLeft: "0px",
                            textAlign: "left",
                            fontSize: "0.9rem",
                          }}
                        >
                          {row.load ? (
                            <React.Fragment>
                              <span style={{ marginRight: "5px" }}>Load</span>
                              <span>{Math.round(row.load)}</span>
                            </React.Fragment>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      {(row.type === "workout" || row.type === "pastworkout") &&
                        row.activityType !== "Strength" && (
                          <BarChart
                            height={150}
                            workoutDoc={row?.structure}
                            yAxisUnit="Watts"
                            currentMetric={"Power"}
                            showLegends={false}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : null
          )}
        </Grid>
      </Grid>
      <WorkoutTextView
        open={open}
        setOpen={setOpen}
        woObj={woObj}
      ></WorkoutTextView>
      <div>Last Updated: {new Date(data?.time).toLocaleString()}</div>
    </Paper>
  );
}
