import React from "react";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Box from "@mui/material/Box";

import FitnessFormChart from "./fitnessFormChart";
import ZoneBreakup from "./zoneBreakup";
import WorkoutChart from "./calendar/subview/components/workoutChart/workoutChart";
export function Analysis() {
  return (
    <div>
      <Box>
        {/* <WorkoutChart /> */}
        <ZoneBreakup></ZoneBreakup>
      </Box>
      {/* <FitnessFormChart /> */}
    </div>
  );
}

export default withAuthenticationRequired(Analysis, {
  onRedirecting: () => <Loading />,
});
