import React, { Suspense, useState, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery, useTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from 'react-query';

import NavBar from "./views/navbar/NavBar";
import Footer from "./components/Footer";
import SettingsModal from "./views/settingsModal/settingsModal";
import Loading from "./components/Loading";
import Home from "./views/Home";
import Signup from "./views/signup";
import Profile from "./views/Profile";
import Dashboard from "./views/dashboard/dashboard";
import Analysis from "./views/analysis";
import Eventscycling from "./views/eventscycling";
import Eventsrunning from "./views/eventsrunning";
import AudaxResults from "./views/audaxResults";
import Privacypolicy from "./views/privacypolicy";
import Calendar from "./views/calendar/calendar";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Thirdpartyauth from "./views/thirdpartyauth/thirdpartyauth";
import Thirdpartyauth2 from "./views/thirdpartyauth/thirdpartyauth2";
import { BikeFitFameWall } from "./views/bikeFitFameWall";
import FitnessFormChart from "./views/fitnessFormChart";
import BlogPost from "./views/blog";
import { CalendarDataProvider } from "./views/calendar/subview/calendarDataProvider";
import { Testimonials } from "./views/testimonials2";
import TestimonialsPage from "./views/testimonials/bikefit";
import ClubDetails from "./views/clubs/clubDetails";
import AllClubs from "./views/clubs/allClubs";
import CreateClub from "./views/clubs/createClub";
import ZoneBreakup from "./views/zoneBreakup";
import ThirdpartyauthStrava from "./views/thirdpartyauth/thirdpartyauthStrava";
import ThirdpartyauthWahoo from "./views/thirdpartyauth/thirdpartyauthWahoo";
import ThirdpartyauthGarmin from "./views/thirdpartyauth/thirdpartyauthGarmin";
import MessagesComponent from "./views/dashboard/components/messages";
const queryClient = new QueryClient();

initFontAwesome();
const ProtectedClubDetails = withAuthenticationRequired(ClubDetails, {
  onRedirecting: () => <Loading />,
});
const ProtectedAllClubs = withAuthenticationRequired(AllClubs, {
  onRedirecting: () => <Loading />,
});

const Main = () => {
  const { isLoading, error, user, isAuthenticated, getIdTokenClaims } =
    useAuth0();
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const theme = useTheme();
  const isUltraWide = useMediaQuery(theme.breakpoints.up("xl"));
  const location = useLocation(); // Now you can use useLocation here
  const [accessToken, setAccessToken] = useState("");

  const toggleSettings = () => setIsSettingsVisible((prev) => !prev);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const idToken = await getIdTokenClaims();
          setAccessToken(idToken.__raw);
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (isAuthenticated && window.fcWidget) {
      window.fcWidget.user.setProperties({
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        // You can add more user specific information here if needed
      });
    }

    const script = document.createElement("script");
    script.src = "https://wchat.freshchat.com/js/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  // if (isLoading) {
  //   return <Loading />;
  // }
  const userEmail = user?.email;
  const appStyle =
    isUltraWide && location.pathname !== "/"
      ? { maxWidth: "1200px", margin: "0 auto" }
      : {};

  return (
    <div id="app" style={appStyle}>
      <NavBar onSettingsClick={toggleSettings} accessToken={accessToken} />
      <div className={`content ${isSettingsVisible ? "content-darken" : ""}`}>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/clubs/:clubId" element={
            <ClubDetails accessToken={accessToken} />
          } />
          <Route path="/clubs" element={<AllClubs accessToken={accessToken} />} />
          <Route path="/clubs/create" element={<CreateClub accessToken={accessToken} />} />

          <Route path="/analysis" element={<Analysis />} />
          <Route path="/thirdpartyauth" element={<Thirdpartyauth />} />
          <Route
            path="/thirdpartyauth2"
            element={<Thirdpartyauth2 accessToken={accessToken} />}
          />
          <Route
            path="/thirdpartyauthstrava"
            element={<ThirdpartyauthStrava accessToken={accessToken} />}
          />
          <Route
            path="/thirdpartyauthwahoo"
            element={<ThirdpartyauthWahoo accessToken={accessToken} />}
          />
          <Route
            path="/thirdpartyauthgarmin"
            element={<ThirdpartyauthGarmin accessToken={accessToken} />}
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                accessToken={accessToken}
                toggleSettings={toggleSettings}
              />
            }
          />
          <Route
            path="/calendar"
            element={
              <CalendarDataProvider user={user}>
                <Calendar accessToken={accessToken} />
              </CalendarDataProvider>
            }
          />
          <Route path="/messages" exact element={<MessagesComponent accessToken={accessToken} />} />
          <Route path="/cyclingevents" element={<Eventscycling />} />
          {/* <Route path="/runningevents" element={<Eventsrunning />} /> */}
          <Route path="/audaxresults" element={<AudaxResults />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/bikefit" element={<BikeFitFameWall />} />
          <Route path="/bikefit2" element={<TestimonialsPage />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/zonecalculator" exact element={<ZoneBreakup />} />

          <Route
            path="/tips"
            element={
              <iframe
                src="https://blog.enduroco.in/"
                style={{ width: "100%", height: "100vh" }}
                title="Tips"
              />
            }
          />
          <Route path="/blog/*" element={<BlogPost />} />
        </Routes>
      </div>
      {isSettingsVisible && (
        <>
          <div className="backdrop" onClick={toggleSettings} />
          <SettingsModal
            accessToken={accessToken}
            onClose={toggleSettings}
            userEmail={userEmail}
          />
        </>
      )}
      <Footer />
    </div>
  );
};
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </QueryClientProvider>
  );
};
export default App;
