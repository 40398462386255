import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Grid, useMediaQuery } from "@mui/material";
import CalendarUpcomingTable2 from "./subview/calendarMobile";
import CalendarDesktop from "./subview/calendarDesktop";
import Loading from "../../components/Loading";

function Calendar({ accessToken }) {
  const { user } = useAuth0();
  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the width as needed

  return (
    <div className="calendar_section">
      <div className="container">
        <div className="row">
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              md={12}
              order={{ xs: 2, md: 1 }}
              alignItems="center"
              justifyContent="center"
              textAlign={"-webkit-center"}
              sx={{ marginX: "auto" }}
            >
              {isMobile ? (
                <CalendarUpcomingTable2 user={user} />
              ) : (
                <CalendarDesktop user={user} accessToken={accessToken} />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Calendar, {
  onRedirecting: () => <Loading />,
});
