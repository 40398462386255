import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Slider,
  Tooltip,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

const Section = styled(Box)(({ theme }) => ({
  padding: "16px",
  margin: "16px 0",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
}));

const SubSection = styled(Box)(({ theme }) => ({
  padding: "12px",
  marginBottom: "20px",
  backgroundColor: theme.palette.grey[100],
  borderRadius: "8px",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
}));

const IntroText = styled(Typography)(({ theme }) => ({
  marginBottom: "16px",
  color: theme.palette.text.secondary,
  fontSize: "14px",
  lineHeight: "1.5",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  fontSize: "13px",
  padding: "1px 2px",
  height: "32px",
  minHeight: "32px",
  boxSizing: "border-box",
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    "&.MuiSelect-select": {
      paddingRight: 0, // Adjust padding to ensure consistent underline breaks
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.divider,
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "14px", // Increase the font size for labels
  paddingLeft: "2px",
  fontWeight: "bold", // Optionally, you can make it bold to stand out
  color: theme.palette.text.primary,
}));

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const numberOfDaysOptions = [5, 6]; // 5 days or 6 days per week

const timeOptions = [
  { value: -1, label: "Open" },
  { value: 0, label: "Rest Day" },
  { value: 30, label: "30 minutes" },
  { value: 60, label: "1 hour" },
  { value: 90, label: "1.5 hours" },
  { value: 120, label: "2 hours" },
  { value: 150, label: "2.5 hours" },
  { value: 180, label: "3 hours" },
];

const trainingPhases = [
  {
    ramp: 10,
    value: 0,
    label: "Tapering",
    text: "Reduces training intensity before a major event, slightly lowering fitness levels but enhancing form and event readiness.",
  },
  {
    ramp: -10,
    value: 1,
    label: "Maintenance",
    text: "Maintains fitness between events to prevent burnout and overtraining, ideal for transitioning between competitions.",
  },
  {
    ramp: -20,
    value: 2,
    label: "Build-Up",
    text: "Gradually enhances fitness and strength, suitable for athletes aiming to boost overall performance steadily.",
  },
  {
    ramp: -30,
    value: 3,
    label: "Quick Build",
    text: "Rapidly increases fitness for upcoming events without a primary focus on strength, intended for short-term performance gains.",
  },
  {
    ramp: -40,
    value: 4,
    label: "Intense Boost",
    text: "Aggressively boosts fitness with a significant injury and fatigue risk, recommended only for seasoned athletes under careful oversight.",
  },
];

const getNearestRampIndex = (targetRamp) => {
  return trainingPhases.reduce((prevIndex, currentPhase, currentIndex) => {
    return Math.abs(currentPhase.ramp - targetRamp) <
      Math.abs(trainingPhases[prevIndex].ramp - targetRamp)
      ? currentIndex
      : prevIndex;
  }, 0);
};

const TimeBasedSection = ({
  userProfile,
  handleChangeTime,
  handleTargetFormPctChange,
  handleChangeUserProfile,
}) => {
  const theme = useTheme();
  const [selectedTime, setSelectedTime] = useState(
    userProfile.volumeSelectionTimeBasedSettings || {}
  );
  const initialRampIndex = getNearestRampIndex(userProfile.targetformpct);
  const [value, setValue] = useState(initialRampIndex);
  const [numberOfDays, setNumberOfDays] = useState(userProfile.numberofdaysweekly || 5);
  const [weekStart, setWeekStart] = useState(userProfile.weekstart || 0);
  const [weekStartOptions, setWeekStartOptions] = useState([]);

  useEffect(() => {
    if (userProfile?.numberofdaysweekly == 6) {
      setWeekStartOptions([
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
      ]);
    } else if (userProfile?.numberofdaysweekly == 5) {
      setWeekStartOptions([
        { label: "Sunday/Wednesday", value: 0 },
        { label: "Monday/Thursday", value: 1 },
      ]);
    }
  }, [userProfile?.numberofdaysweekly]);

  const handleTimeSelectionChange = (day) => (event) => {
    const newValue = event.target.value;
    const newSelection = { ...selectedTime };

    if (newValue === -1) {
      delete newSelection[day];
    } else {
      newSelection[day] = newValue;
    }

    setSelectedTime(newSelection);
    handleChangeTime(day, newValue);
  };

  const handleSliderChange = (event, newValue) => {
    handleTargetFormPctChange(trainingPhases[newValue].ramp);
    setValue(newValue);
  };

  const handleNumberOfDaysChange = (event) => {
    setNumberOfDays(event.target.value);
    handleChangeUserProfile({ numberofdaysweekly: event.target.value });
  };

  const handleWeekStartChange = (event) => {
    setWeekStart(event.target.value);
    handleChangeUserProfile({ weekstart: event.target.value });
  };

  useEffect(() => {
    setSelectedTime(userProfile.volumeSelectionTimeBasedSettings || {});
    setNumberOfDays(userProfile.numberofdaysweekly || 5);
    setWeekStart(userProfile.weekstart || 0);
  }, [userProfile]);

  return (
    <Section>
      <SubSection>
        <Typography
          variant="subtitle1"
          sx={{ marginBottom: "8px", fontWeight: "bold", color: theme.palette.text.primary }}
        >
          Weekly Schedule
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginBottom: "4px" }}>
              <CustomInputLabel shrink>
                Days/Week
              </CustomInputLabel>
              <CustomSelect
                value={numberOfDays}
                onChange={handleNumberOfDaysChange}
                displayEmpty
                notched
              >
                {numberOfDaysOptions.map((option) => (
                  <MenuItem key={option} value={option} sx={{ fontSize: "13px", padding: "4px 8px" }}>
                    {option} Days / Week
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginBottom: "4px" }}>
              <CustomInputLabel shrink>
                Rest Day
              </CustomInputLabel>
              <CustomSelect
                value={weekStart}
                onChange={handleWeekStartChange}
                displayEmpty
                notched
              >
                {weekStartOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: "13px", padding: "4px 8px" }}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
        </Grid>
      </SubSection>

      <IntroText>
        Set available time for each day. The system will adjust the volume accordingly.
      </IntroText>

      <Grid container spacing={1}>
        {daysOfWeek.map((day) => (
          <Grid item xs={4} key={day}>
            <FormControl fullWidth sx={{ marginBottom: "4px" }}>
              <CustomInputLabel shrink>
                {day}
              </CustomInputLabel>
              <CustomSelect
                value={selectedTime[day] !== undefined ? selectedTime[day] : -1}
                onChange={handleTimeSelectionChange(day)}
                displayEmpty
                notched
                label={day}
                renderValue={(selected) => {
                  if (selected === -1) {
                    return (
                      <Tooltip title="No time restrictions on this day">
                        <Typography sx={{ fontSize: "12px", padding: "1px 2px" }}>Open</Typography>
                      </Tooltip>
                    );
                  } else if (selected === 0) {
                    return (
                      <Tooltip title="This is a rest day with no training planned">
                        <Typography
                          sx={{
                            fontSize: "12px",
                            padding: "1px 2px",
                            color: theme.palette.primary.main,
                            fontWeight: "bold",
                          }}
                        >
                          Rest Day
                        </Typography>
                      </Tooltip>
                    );
                  }
                  const option = timeOptions.find((option) => option.value === selected);
                  return option ? option.label : selected;
                }}
              >
                {timeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: "13px", padding: "4px 8px" }}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "12px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: "8px", fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Adjust Training Ramp Rate
            </Typography>
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={5} sx={{ height: "100%" }}>
                <Slider
                  orientation="vertical"
                  value={typeof value === "number" ? value : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={trainingPhases.map(({ value, label }) => ({
                    value,
                    label,
                  }))}
                  min={0}
                  max={trainingPhases.length - 1}
                  sx={{ height: "150px" }}
                />
              </Grid>
              <Grid item xs={7}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      wordWrap: "break-word",
                      fontStyle: "italic",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {trainingPhases[value].text}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default TimeBasedSection;
