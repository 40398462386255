import React, { useCallback } from "react";
import axios from "axios";
import { Button, Snackbar, Alert } from "@mui/material";

const buttonStyle = {
  marginTop: 8,
  marginBottom: 8,
  width: "100%",
  padding: "15px 0",
  fontSize: "1rem",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textTransform: "none",
  letterSpacing: "0.5px",
  borderRadius: "5px",
};

const StartTrial = ({ email, revalidateProfile, accessToken }) => {
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('info');

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const startTrialHandler = useCallback(async () => {
    if (!accessToken) {
      setSnackMessage("Access Token is missing!");
      setSeverity("error");
      setSnackOpen(true);
      return; // Early exit if access token is not available
    }

    try {
      const url = 'https://api2.enduroco.in/subscription/starttrial';
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.post(url, {}, { headers });
      console.log(response);
      setSnackMessage("Trial started successfully!");
      setSeverity("success");
      setTimeout(revalidateProfile, 1000); // Revalidate the profile after 1 second
    } catch (err) {
      console.error(err);
      setSnackMessage("Failed to start the trial. Please try again.");
      setSeverity("error");
    } finally {
      setSnackOpen(true);
    }
  }, [email, revalidateProfile, accessToken]);

  return (
    <>
      <Button
        onClick={startTrialHandler}
        variant="contained"
        color="primary"
        style={buttonStyle}
      >
        Start Free Trial
      </Button>
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StartTrial;
