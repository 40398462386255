import React from "react";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import CalendarEvent from "./CalendarEvent"; // Adjust the import path as necessary

const CalendarDay = ({
  day,
  events,
  setCurrentMonth,
  setOpenWorkoutView,
  setSelectedEvent,
}) => {
  const theme = useTheme();
  const eventsList = events.filter((event) =>
    moment(event.date).isSame(day, "day")
  );
  const isToday = moment().isSame(day, "day");
  const isCurrentMonth = moment().isSame(day, "month");
  const dayStyles = {
    textAlign: "center",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxSizing: "border-box",
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    backgroundColor: isToday
      ? theme.palette.secondary.main
      : theme.palette.background.paper,
    color: isToday
      ? theme.palette.secondary.contrastText
      : theme.palette.text.primary,
    fontWeight: isToday ? "bold" : "normal",
    paddingTop: 0,
    borderRadius: "2px",
    padding: "2px",
  };

  const eventContainerStyles = {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0px", // Remove padding to avoid unwanted spacing inside the container
  };

  return (
    <Grid xs style={dayStyles}>
      <Typography
        variant="body2"
        component="div"
        style={{ fontWeight: "bold" }}
      >
        {day.format("D")}
      </Typography>
      <div style={eventContainerStyles}>
        {eventsList.map((event, idx) => (
          <CalendarEvent
            event={event}
            setOpenWorkoutView={setOpenWorkoutView}
            setSelectedEvent={setSelectedEvent}
            key={idx}
            marginBottom={idx === eventsList.length - 1 ? "0px" : "5px"}
          />
        ))}
      </div>
    </Grid>
  );
};

export default CalendarDay;
