import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import useSWR from "swr";
import Paper from "@mui/material/Paper";
import { getConfig } from "../config";
import StartTrial from "./startTrial";
import Link from "@mui/material/Link";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const fetcher = (url) => fetch(url).then((res) => res.json());

const paperStyle = {
  padding: "20px",
  backgroundColor: "#FFFDE7",
  margin: "20px 0",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const PaymentPending = ({ email, profile, revalidateProfile, refresh, accessToken }) => {
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [refreshLocal, setRefreshLocal] = useState(refresh);
  const config = getConfig();
  const { data: status, mutate } = useSWR(
    `${config.apiEndpoint}/api/v1/user/subscriptionStatus?email=${email}`,
    fetcher
  );

  useEffect(() => {
    if (status?.invoices && !status.appSubscription.usingInApp) {
      setPendingInvoices(
        status.invoices.filter((invoice) => invoice.status === "pending")
      );
    }
    if (refreshLocal !== refresh) {
      setRefreshLocal(refresh);
      mutate();
    }
  }, [status, refresh, refreshLocal, mutate]);

  const handleRevalidateAndMutate = useCallback(async () => {
    await revalidateProfile();
    mutate();
  }, [revalidateProfile, mutate]);

  if (!status) return null;
  if (!status.displayBanner) return null;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={12} md={8}>
        <Paper elevation={3} style={paperStyle}>
          <Typography
            variant="h6"
            style={{ color: "#333", marginBottom: "10px" }}
          >
            {!status.trialEndDate && !status.appSubscription.usingInApp && (
              <StartTrial
                email={email}
                revalidateProfile={handleRevalidateAndMutate}
                accessToken={accessToken}
              />
            )}
          </Typography>
          <Typography
            variant="body1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <AccessTimeIcon style={{ marginRight: "5px" }} />
            {status.displayBanner}
          </Typography>
          {pendingInvoices.length > 0 && (
            <>
              <Typography variant="body2" style={{ marginTop: "10px" }}>
                Pending Invoice:
              </Typography>
              {pendingInvoices.map((invoice, index) => (
                <Typography key={index} variant="body2">
                  <Link href={invoice.url} target="_blank" rel="noopener">
                    Invoice Created on{" "}
                    {new Date(invoice.creationDate).toLocaleDateString()})
                  </Link>
                </Typography>
              ))}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PaymentPending;
