const mapWorkoutsToEvents = (data) => {
  if (!data || !data.workouts) return [];

  return data.workouts.flatMap((day) =>
    day.workouts.map((workout) => ({
      ...workout,
      date: day.workoutdate,
      woType: workout.woType || workout.type,
      completed: false,
      eventtype: day.type,
      load: Math.round(
        workout.load_edited ||
        workout.tss ||
        workout.pace_load ||
        workout.heart_rate_load ||
        workout.training_load
      ),
      duration:
        workout.duration !== undefined
          ? Math.round(workout.workout_doc?.duration / 60)
          : workout.duration,
      structure: workout.workout_doc,
    }))
  );
};

export default mapWorkoutsToEvents;
