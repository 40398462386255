import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import * as d3 from "d3";
import BarChart from "./components/BarChart";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import moment from "moment";
import "./styles.css";
import Grid from "@mui/material/Unstable_Grid2";

import { Container } from "@mui/material";
import { YouTubeEmbed } from "./components/YouTubeEmbed";
import { MetricButtons } from "./components/MetricButtons";
import { DeleteActivityButton } from "./components/DeleteActivityButton";
import { getIconForEventType } from "../../../../../components/getIconForEventType";
export default function WorkoutChart({ workout, accessToken }) {
  console.log({ workout })
  const formatKey = (key) => {
    const words = key.split("_");
    if (words.length === 1) {
      return key.toUpperCase();
    }
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const getStravaActivityUrl = (stravaId) => {
    return `https://www.strava.com/activities/${stravaId}`;
  };
  const loads = workout?.loads;
  const nonZeroLoads = Object.entries(loads)
    .filter(([_, value]) => value > 0)
    .map(([key, value]) => ({ key: formatKey(key), value }));
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const chartRef = useRef();
  const [workoutDoc, setWorkoutDoc] = useState();
  const [currentMetric, setCurrentMetric] = useState("Power");
  let resizeTimer = null;

  function resetWidth() {
    if (chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect();

      // Set width and height as a fraction of the screen dimensions
      const proportionalWidth = window.innerWidth * 0.45;
      const proportionalHeight = window.innerHeight * 0.25;

      // setWidth(proportionalWidth);
      setHeight(proportionalHeight);
    }
  }
  const renderDescriptionItem = (item, key, isBlock = false) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (isBlock) {
      // If it's a block, render the content and parse HTML
      switch (item.type) {
        case "link":
          return (
            <Typography
              align="left"
              variant="body1"
              paragraph
              style={{ fontWeight: 400 }}
              key={key}
            >
              <a
                href={item.content.match(urlRegex)[0]}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </Typography>
          );
        case "text":
        default:
          return (
            <Typography
              align="left"
              variant="body1"
              paragraph={true} // Adds bottom margin
              style={{ fontWeight: 400 }} // Normal font weight
              key={key}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          );
      }
    } else {
      // Original behaviour for non-block items
      const parts = item.split(urlRegex);
      return parts.map((part, index) => {
        if (urlRegex.test(part)) {
          return part.includes("youtube.com") ? (
            <YouTubeEmbed url={part} key={index} />
          ) : (
            <Typography
              align="center"
              variant="body1"
              paragraph={true} // Adds bottom margin
              style={{ fontWeight: 400 }} // Normal font weight
              key={index}
            >
              <Link href={part} target="_blank">
                {"Click Here"}
              </Link>
            </Typography>
          );
        } else {
          return part.split("\\n").map((line, lineKey) => (
            <Typography
              align="left"
              variant="body1"
              paragraph={true} // Adds bottom margin
              style={{ fontWeight: 400 }} // Normal font weight
              key={`${key}-${index}-${lineKey}`}
            >
              {line}
            </Typography>
          ));
        }
      });
    }
  };
  const formatBestPowers = (bestPowers) => {
    if (!bestPowers) return [];
    const formattedPowers = [];
    for (const [key, value] of Object.entries(bestPowers)) {
      if (value > 0) {
        const formattedKey = key
          .split("_")
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
          .join(" ");
        formattedPowers.push({ key: formattedKey, value });
      }
    }
    return formattedPowers;
  };
  const ref = useRef(null); // Ref to attach to the component

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    const timeoutId = setTimeout(updateWidth, 300); // Adjust delay as needed

    window.addEventListener("resize", updateWidth); // Update width on resize

    return () => window.removeEventListener("resize", updateWidth); // Cleanup listener
  }, []);
  useEffect(() => {
    setWorkoutDoc(workout);

    window.addEventListener("resize", resetWidthOnResize);

    setTimeout(resetWidth, 10);

    return () => {
      window.removeEventListener("resize", resetWidth);
    };
  }, [currentMetric]);

  function resetWidthOnResize() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(resetWidth, 50);
  }

  function secondsToHHMMSS(d) {
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  function formatPace(value) {
    const minutes = Math.floor(value / 60);
    const seconds = Math.round(value - minutes * 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  if (!workoutDoc) return null;
  console.log({ workoutDoc });
  return (
    <>
      <div className="">
        {workout.type !== "activity" && (
          <MetricButtons
            currentMetric={currentMetric}
            setCurrentMetric={setCurrentMetric}
            activityType={workoutDoc.woType}
          />
        )}
        <div className="chart-div">
          <div className="child-div">
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                m: 0, // Reduce margin
                p: 0, // Reduce padding
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                {getIconForEventType(workoutDoc.woType, "large")}
              </Box>
              <Typography
                id="modal-modal-title"
                variant="h5" // Changed to a smaller variant
                component="h2"
                align="center"
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.7rem",
                    md: "1.7rem",
                  },
                  m: 0, // Reduce margin
                }}
              >
                {workoutDoc.title}
              </Typography>
            </Box>
          </div>
          <div className="child-div">
            Duration
            <br /> <span className="bold">
              {workoutDoc.readableDuration}
            </span>{" "}
          </div>
          <div className="child-div">
            Load
            <br /> <span className="bold">{workoutDoc.load}</span>{" "}
          </div>
          <div className="child-div">
            Date
            <br />{" "}
            <span className="bold">
              {moment(workoutDoc.date).format("dddd")}
              {", "}
              {moment(workoutDoc.date).format("DD")}{" "}
              {moment(workoutDoc.date).format("MMM")}
            </span>
          </div>
        </div>
        {workout.type !== "activity" ? (
          <>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: "8px", // Reduced padding
                    }}
                  >
                    {workout
                      ? workout.descriptionBlocks &&
                        workout.descriptionBlocks.length > 0
                        ? workout.descriptionBlocks.map((block, index) =>
                          renderDescriptionItem(block, index, true)
                        )
                        : workout.description
                          ? workout.description
                            .split("\n")
                            .map((item, index) =>
                              renderDescriptionItem(item, index)
                            )
                          : null
                      : null}
                  </Box>
                </Grid>
                <Grid md={8} xs={12} ref={ref}>
                  <div id="chart_main_div" ref={chartRef}>
                    {width && workoutDoc.structure && (
                      <BarChart
                        ChartWidth={width}
                        height={height}
                        workoutDoc={workoutDoc.structure}
                        yAxisUnit={
                          currentMetric === "Power"
                            ? "WATTS"
                            : currentMetric === "HR"
                              ? "BPM"
                              : "% Threshold Pace"
                        }
                        currentMetric={currentMetric}
                      />
                    )}
                  </div>
                </Grid>
                {workout.structure && (
                  <Grid md={4} xs={12}>
                    <div
                      className="details"
                      style={{
                        display: "grid",
                        gap: "8px",
                        backgroundColor: "#f7f7f7",
                        padding: "8px",
                        borderRadius: "6px",
                      }}
                    >
                      <div className="flex-container">
                        {workoutDoc?.structure?.steps.map((step, index) => {
                          const metricValueMultiplier =
                            currentMetric === "Power"
                              ? workoutDoc.structure.ftp
                              : currentMetric === "HR"
                                ? workoutDoc.structure.thresholdHr
                                : currentMetric === "Pace"
                                  ? 1000 / workoutDoc.structure.thresholdPace
                                  : undefined;

                          const renderMetricValue = (value) => {
                            if (currentMetric === "Pace") {
                              return formatPace(
                                Math.round(
                                  metricValueMultiplier / (value / 100)
                                )
                              );
                            } else {
                              return `${Math.round(
                                (value / 100) * metricValueMultiplier
                              )} ${currentMetric === "Power"
                                ? "Watts"
                                : currentMetric === "HR"
                                  ? "BPM"
                                  : "min/km"
                                }`;
                            }
                          };

                          return (
                            <div
                              key={"step_" + index}
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0px",
                                padding: "0px",
                                backgroundColor: "#ffffff",
                                borderRadius: "0px",
                              }}
                            >
                              {step.reps ? (
                                <div
                                  className="reps"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "1em",
                                  }}
                                >
                                  {step.reps}x
                                </div>
                              ) : null}
                              {(step.reps ? step.steps : [step]).map(
                                (s, sIndex) => (
                                  <div
                                    key={"s_" + sIndex}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      className="step-time"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {secondsToHHMMSS(s.duration, true)}
                                    </span>
                                    <span
                                      className="step-power"
                                      style={{ fontSize: "0.9em" }}
                                    >
                                      {renderMetricValue(
                                        s[currentMetric.toLowerCase()].value
                                      )}
                                    </span>
                                    <span
                                      className="step-wattage"
                                      style={{
                                        opacity: 0.6,
                                        fontSize: "0.8em",
                                      }}
                                    >
                                      {Math.round(
                                        s[currentMetric.toLowerCase()].value
                                      )}
                                      %
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Container>
            <div id="div_template"></div>
          </>
        ) : (
          <>
            <Table size="small">
              <TableBody>
                <TableRow key={"strava"}>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    {workout.stravaId && (
                      <Link
                        href={getStravaActivityUrl(workout.stravaId)}
                        target="_blank"
                      >
                        View on Strava
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
                {formatBestPowers(workout?.bestPowers).map((power, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {power.key}
                    </TableCell>
                    <TableCell align="right">{power.value} W</TableCell>
                  </TableRow>
                ))}
                {/* Additional rows for nonZeroLoads */}
                {nonZeroLoads.map((load, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {load.key.replace(/_/g, " ")}
                    </TableCell>
                    <TableCell align="right">{load.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>{" "}
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <DeleteActivityButton
                activityId={workout.stravaId}
                token={accessToken || ""}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
