import React from "react";
import { Typography, Box } from "@mui/material";
import CalendarHeatmap from "react-github-contribution-calendar";
import moment from "moment";
import { CustomCard, CustomCardContent, Heading } from "../style/CustomCard"; // Adjust the import path as necessary

const HeatMapCard = ({ data }) => {
  // Check if data.dailyload is defined, otherwise use an empty array as a fallback
  const validData = data && data.dailyload ? data.dailyload.slice(0, -14) : [];

  if (validData.length === 0) {
    // Handle the case where there is no data
    // This could be a loading indicator or a message indicating no data is available
    return <Typography>No data available</Typography>;
  }

  const startDate = moment().subtract(validData.length - 1, "days");

  // Define panel colors based on your theme
  const panelColors = [
    "#EEEEEE", // No load - lightest color
    "#ff8a65", // Medium load - secondary color
    "#ac5808", // Very high load - darker shade of primary color
  ];

  // Create values object for the heatmap
  const values = validData.reduce((acc, load, index) => {
    const date = moment(startDate).add(index, "days").format("YYYY-MM-DD");
    acc[date] = load; // Assign the load directly as the value
    return acc;
  }, {});

  // Set the 'until' date to the last date in your data range
  const until = moment(startDate)
    .add(validData.length - 1, "days")
    .format("YYYY-MM-DD");

  return (
    <CustomCard>
      <CustomCardContent>
        <Heading>Load Heatmap</Heading>
        <CalendarHeatmap
          values={values}
          until={until}
          panelColors={panelColors}
        />
      </CustomCardContent>
    </CustomCard>
  );
};

export default HeatMapCard;
