import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CalendarEventTopStrip from "./CalendarEventTopStrip"; // Adjust the import path as necessary
import { LoadTypography } from "./loadTypography"; // Adjust the import path as necessary

const CalendarEvent = ({ event, setOpenWorkoutView, setSelectedEvent, marginBottom }) => {
  const theme = useTheme();

  const eventStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: "5px",
    padding: "0px",
    backgroundColor:
      event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.main
        : theme.palette.eventOtherBackground.main,
    cursor: "pointer",
    marginBottom: marginBottom,
    transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease", // Smooth transitions for visual effects
  };

  const hoverStyle = {
    backgroundColor:
      event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.hover
        : theme.palette.eventOtherBackground.hover,
    transform: "scale(1.02)", // Slightly scale up the event card on hover
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Add a subtle shadow to lift the card visually
  };

  const handleEventClick = (event) => {
    const formatDuration = (durationInSeconds) => {
      if (!durationInSeconds) return null;
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
    };

    setSelectedEvent({
      ...event,
      duration: formatDuration(
        event.eventtype === "activity" ? event.movingTime : event.duration * 60
      ),
      type:
        event.eventtype === "pastworkout" || !event.eventtype
          ? "workout"
          : event.eventtype,
      loads: {
        training_load: event.load,
        power_load: Math.round(event.tss),
        pace_load: Math.round(event.pace_load),
        heart_rate_load: Math.round(event.heart_rate_load),
      },
    });
    setOpenWorkoutView(true);
  };
  return (
    <div
      style={eventStyle}
      onClick={() => handleEventClick(event)}
      onMouseEnter={e => Object.assign(e.currentTarget.style, hoverStyle)}
      onMouseLeave={e => {
        e.currentTarget.style.backgroundColor = eventStyle.backgroundColor;
        e.currentTarget.style.transform = 'none';
        e.currentTarget.style.boxShadow = 'none';
      }}
    >
      <CalendarEventTopStrip event={event} />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        component="div"
        style={{
          fontSize: "0.75rem",
          marginLeft: "8px",
          color: theme.palette.text.secondary,
        }}
      >
        {event.title}
      </Typography>
      <LoadTypography load={event.load} />
    </div>
  );
};

export default CalendarEvent;