import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Link, Box } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import logofooter from "../assets/logo_orange.png";

const Footer = () => (
  <Box
    sx={{
      backgroundColor: "#343a40",
      padding: "20px",
      textAlign: "center",
      color: "white",
    }}
  >
    <Grid container spacing={3} justifyContent="center">
      <Grid xs={12} sm={4}>
        <img
          src={logofooter}
          alt="footer logo"
          width={120}
          style={{ marginBottom: "10px" }}
        />
        <Typography variant="body1">AI-driven real-time coaching.</Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography variant="h6" gutterBottom>
          Tools
        </Typography>
        <Link
          href="https://servicestatus.enduroco.in"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Service Status
        </Link>
        <Link
          href="/cyclingevents"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Cycling Brevets
        </Link>
        <Link
          href="/bikefit"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Bike Fit
        </Link>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography variant="h6" gutterBottom>
          About Us
        </Typography>
        <Link
          href="https://forum.enduroco.in"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Forum
        </Link>
        <Link
          href="/testimonials"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Testimonials
        </Link>

        <Link
          href="/privacypolicy"
          color="inherit"
          sx={{ display: "block", marginBottom: "5px" }}
        >
          Privacy Policy
        </Link>
      </Grid>
    </Grid>
    <Typography variant="body1" sx={{ marginTop: "15px", marginBottom: "5px" }}>
      Follow us on:
    </Typography>
    <Link href="https://www.instagram.com/enduroco_in/" color="inherit">
      <InstagramIcon sx={{ marginRight: "10px" }} />
    </Link>
    <Link href="https://twitter.com/enduroco_ai/" color="inherit">
      <TwitterIcon />
    </Link>
    <Typography variant="body1" sx={{ marginTop: "15px" }}>
      © 2024 EnduroCo
    </Typography>
  </Box>
);

export default Footer;
